<template>
  <div v-if="response || message">
    <h2 :class="getClass(status)" v-if="message || errors.length">
      <span v-if="message">{{ message }}</span>
      <template v-if="response">
        <span v-for="(value, _, index) in errors" :key="index">{{
          value
        }}</span>
      </template>
    </h2>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: ["className"],
  computed: {
    ...mapGetters({
      response: "error/getErrorResponse",
      message: "error/getMessage",
      status: "error/getStatus",
    }),
    errors() {
      if (this.response.errors) {
        const errors = Object.values(this.response.errors);
        return this.flat(errors).map((message) => {
          // if (this.$te(message)) {
          //   return this.$t(message);
          // }
          return message;
        });
      }
      const m = this.response.message;
      // if (this.$te(m)) {
      //   return [this.$t(m)];
      // }
      if (m) {
        return [m];
      }
      return [];
    },
  },
  methods: {
    getClass(status) {
      switch (status) {
        case "success":
          return "alert alert-success";
        case "error":
          return "alert alert-danger";
      }
    },
    flat(input, depth = 1, stack = []) {
      for (let item of input) {
        if (item instanceof Array && depth > 0) {
          this.flat(item, depth - 1, stack);
        } else {
          stack.push(item);
        }
      }

      return stack;
    },
  },
};
</script>

<style scoped lang="scss">
.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}
.alert-danger {
  color: $alert-color;
  border-color: $border-alert-color;
}
.alert-success {
  color: $border-info-color;
  border-color: $info-color;
}
</style>
