<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "app",
  computed: {
    ...mapGetters("auth", {
      loggedin: "loggedin",
    }),
  },
  created() {
    if (!this.loggedin) {
      this.$router.push({ name: "login" });
    }
  },
};
</script>
