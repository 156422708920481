import { createI18n } from "vue-i18n";

import i18nEnBy from "./locales/en_BY.json";
import i18nBy from "./locales/ru_BY.json";
import i18nRu from "./locales/ru_RU.json";
import i18nAm from "./locales/hy_AM.json";
import i18nUa from "./locales/uk_UA.json";
import i18nEnSportBenefit from "./locales/en.json";
import i18nLt from "./locales/lt_LT.json";

const format24 = {
  short: {
    year: "numeric",
    month: "short",
    day: "numeric",
  },
  long: {
    year: "numeric",
    month: "short",
    day: "numeric",
    weekday: "short",
    hour: "numeric",
    minute: "numeric",
    hour12: false,
  },
};

const datetimeFormats = {
  "en-BY": {
    short: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "short",
      hour: "numeric",
      minute: "numeric",
    },
  },
  "en-UA": format24,
  "ru-RU": format24,
  "ru-BY": format24,
  "hy-AM": format24,
  "uk-UA": format24,
  "lt-LT": format24,
  "en-LT": format24,
  en: format24,
};

const numberFormats = {
  "en-BY": {
    currency: {
      style: "currency",
      currency: "BYN",
    },
  },
  "ru-RU": {
    currency: {
      style: "currency",
      currency: "RUB",
      currencyDisplay: "symbol",
    },
  },
  "ru-BY": {
    currency: {
      style: "currency",
      currency: "BYN",
      currencyDisplay: "symbol",
    },
  },
  "hy-AM": {
    currency: {
      style: "currency",
      currency: "AMD",
      currencyDisplay: "symbol",
    },
  },
  "en-UA": {
    currency: {
      style: "currency",
      currency: "UAH",
      currencyDisplay: "symbol",
    },
  },
  "uk-UA": {
    currency: {
      style: "currency",
      currency: "UAH",
      currencyDisplay: "symbol",
    },
  },
  en: {
    currency: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
    },
  },
  "en-LT": {
    currency: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
    },
  },
  "lt-LT": {
    currency: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
    },
  },
};

export default createI18n({
  datetimeFormats,
  numberFormats,
  locale: "en-ZZ",
  fallbackLocale: "en-ZZ",
  messages: {
    "en-ZZ": i18nEnSportBenefit,
    "en-BY": i18nEnBy,
    "en-UA": i18nEnSportBenefit,
    "ru-BY": i18nBy,
    "ru-RU": i18nRu,
    "hy-AM": i18nAm,
    "uk-UA": i18nUa,
    "lt-LT": i18nLt,
    "en-LT": i18nEnSportBenefit,
    en: i18nEnSportBenefit,
  },
});
