<template>
  <a
    v-if="isUpdateAllowed"
    href="/app_update"
    class="update-link"
    target="_blank"
  >
    {{ $t("app.update") }}
  </a>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "UpdateAppLink",
  data() {
    return {
      isUpdateAllowed: false,
    };
  },
  computed: {
    ...mapGetters({
      current_locale: "auth/locale",
      supportsPWA: "browser/supportsPWA",
    }),
  },
  created() {
    const isBY =
      this.current_locale === "ru_BY" || this.current_locale === "en_BY";
    this.isUpdateAllowed = isBY && this.supportsPWA;
  },
};
</script>
<style scoped>
.update-link {
  background-color: #e00000;
  border-radius: 24px;
  padding: 14px 16px;
  text-decoration: none;
  color: white;
  width: fit-content;
}
</style>
