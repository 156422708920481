<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="30"
    viewBox="0 0 22 30"
    fill="none"
  >
    <path
      d="M2.99967 29.3944C2.26634 29.3944 1.63856 29.1333 1.11634 28.611C0.594119 28.0888 0.333008 27.461 0.333008 26.7277V12.061C0.333008 11.3277 0.594119 10.6999 1.11634 10.1777C1.63856 9.65548 2.26634 9.39437 2.99967 9.39437H6.99967V12.061H2.99967V26.7277H18.9997V12.061H14.9997V9.39437H18.9997C19.733 9.39437 20.3608 9.65548 20.883 10.1777C21.4052 10.6999 21.6663 11.3277 21.6663 12.061V26.7277C21.6663 27.461 21.4052 28.0888 20.883 28.611C20.3608 29.1333 19.733 29.3944 18.9997 29.3944H2.99967ZM9.66634 20.061V5.16104L7.53301 7.29437L5.66634 5.39437L10.9997 0.0610352L16.333 5.39437L14.4663 7.29437L12.333 5.16104V20.061H9.66634Z"
      fill="black"
    />
  </svg>
</template>
