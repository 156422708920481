/**
 * Created by Alexander Golovnya on 2019-01-02.
 */
import api from "@/store/api/visit";
import * as Sentry from "@sentry/vue";
import {
  INIT_AUTH,
  AUTH_MUTATION_SET_SUPPORT_PHONE,
} from "@/store/mutation-types";

// initial state
const state = {
  visits: [],
  accepted_visits: [],
  processing: null,
  online: true,
  require_photo: false,
};

// getters
const getters = {
  getActiveVisit: (state) => state.processing,
  photoIsRequired: (state) => state.require_photo,
};

function extractErrorMessage(visit) {
  const status =
    ["accepted", "app_holder_passed"].indexOf(visit.status) !== -1
      ? "success"
      : "error";
  let message = "";
  switch (visit.status) {
    case "card_weekly_limit":
    case "card_monthly_limit":
    case "card_daily_limit":
    case "unknown_barcode":
    case "deactivated_card":
    case "supplier_offline":
    case "supplier_reject_wrong_id":
    case "supplier_reject_wrong_other":
    case "aggregator_invalid_visit_status":
    case "app_holder_reject":
    case "app_holder_timeout":
    case "app_holder_limit":
    case "app_holder_wait":
    case "accepted":
    case "app_holder_passed":
    case "supplier_reject_refused_copayment":
    case "aggregator_invalid_attraction":
    case "aggregator_no_visit_found":
      message = visit.reason_text;
      break;
    case undefined && visit.message:
      message = visit.message;
      break;
  }
  return { message, status };
}

// actions
const actions = {
  onlineStatus({ commit }, onlineStatus) {
    commit("error/clear", undefined, { root: true });
    if (!onlineStatus) {
      commit(
        "error/set_error",
        { message: "Проверьте интернет соединение" },
        { root: true }
      );
    }
    commit("online", onlineStatus);
  },
  async getVisit({ commit, rootGetters }) {
    if (!state.online) {
      return "new_registration";
    }
    try {
      const coords = rootGetters["auth/getCoords"];
      const data = await api.hello(coords.latitude, coords.longitude);
      if (data.visit && data.visit.status === "app_holder_wait") {
        const input = { barcode_text: data.visit.id };
        input.supplier_id = rootGetters["auth/getSupplierId"];
        commit("registerVisit", {
          newVisit: data.visit,
          input,
          require_photo: data.visit.require_photo,
        });
        return "accepting_active_visit";
      }

      if (data.support_phone) {
        commit(`auth/${AUTH_MUTATION_SET_SUPPORT_PHONE}`, data.support_phone, {
          root: true,
        });
      }
    } catch (error) {
      commit("error/set_message", error, { root: true });
    }
  },
  async getPhotoUrl(content, id) {
    const response = await api.photo(id);
    return response;
  },
  async confirmed({ commit, state, rootGetters }) {
    commit("error/clear", undefined, { root: true });
    commit("setVisitStatus", { status: "supplier_accepted", reasonText: "" });
    const attractions = rootGetters["auth/getAttractionsByScannedCards"];
    let newRoute = "holder_choose_attraction";
    if (state.processing.attraction && state.processing.attraction.id) {
      newRoute = await actions.sendVisitToServer({ commit, state });
    } else if (
      attractions.length === 1 &&
      attractions.filter((attraction) => attraction.passed_cards !== 0)
        .length === 1
    ) {
      newRoute = await actions.attachAttractionToVisit(
        { commit, state },
        attractions[0]
      );
    }
    return newRoute;
  },
  async confirmedOtherPerson({ commit, state, rootGetters }) {
    commit("error/clear", undefined, { root: true });
    commit("setVisitStatus", {
      status: "app_other_passed",
      reasonText: "To block candidate",
    });
    const attractions = rootGetters["auth/getAttractionsByScannedCards"];
    let newRoute = "holder_choose_attraction";
    if (state.processing.attraction && state.processing.attraction.id) {
      newRoute = await actions.sendVisitToServer({ commit, state });
    } else if (
      attractions.length === 1 &&
      attractions.filter((attraction) => attraction.passed_cards !== 0)
        .length === 1
    ) {
      newRoute = await actions.attachAttractionToVisit(
        { commit, state },
        attractions[0]
      );
    }
    return newRoute;
  },
  /**
   * If internet is working - go back, otherwise - show error
   * @param commit
   * @param state
   * @param input
   * @returns {Promise<void>}
   */
  async rejected({ commit, state }) {
    commit("error/clear", undefined, { root: true });
    commit("setVisitStatus", {
      status: "supplier_reject_wrong_id",
      reasonText: "",
    });
    const response = await api.sendVisits(state.processing);
    const visit = response.data.data;
    commit("setVisitStatus", {
      status: visit.status,
      reasonText: visit.reason_text,
    });
    const messageObj = extractErrorMessage(visit);
    if (messageObj.message) {
      commit("error/set_message_object", messageObj, { root: true });
    }
  },
  async attachAttractionToVisit({ commit, state }, attraction) {
    const visit = state.processing;
    if (!visit) {
      return "new_registration";
    }
    commit("error/clear", undefined, { root: true });
    commit("setVisitAttraction", attraction);
    const routeName = await actions.sendVisitToServer({ commit, state });
    return routeName;
  },
  /**
   * in any failed case - go to rejected list of visits
   * otherwise - show list of
   * @param commit
   * @param state
   * @returns {Promise<string>}
   */
  async sendVisitToServer({ commit, state }) {
    try {
      const response = await api.sendVisits(state.processing);
      const visit = response.data.data;
      commit("setVisitStatus", {
        status: visit.status,
        reasonText: visit.reason_text,
      });
      const messageObj = extractErrorMessage(visit);
      if (messageObj.message) {
        commit("error/set_message_object", messageObj, { root: true });
        return "reject_active_visit";
      }
      commit("error/set_success", visit.reason_text, { root: true });
      return "status_active_visit";
    } catch (error) {
      commit("setVisitStatus", {
        status: "error_500",
        reasonText: "Извините, что-то пошло не так.",
      });
      Sentry.captureException(error);
      return "reject_active_visit";
    }
  },
  async getAcceptedVisits({ commit }, input) {
    commit("error/clear", undefined, { root: true });
    const response = await api.accepted(input);
    const visits = response.data.data;
    commit("setAcceptedVisits", visits);
    return visits;
  },
  async getVisits({ commit }, input) {
    commit("error/clear", undefined, { root: true });
    const response = await api.list(input);
    const visits = response.data.data;
    commit("setVisits", visits);
    return visits;
  },
};

// mutations
const mutations = {
  online(state, online) {
    state.online = online;
  },
  [INIT_AUTH](state) {
    // Check if the ID exists
    if (localStorage.getItem("store_visit_processing")) {
      try {
        const oldVisit = JSON.parse(
          localStorage.getItem("store_visit_processing")
        );
        if (
          oldVisit &&
          oldVisit.id &&
          oldVisit.holder &&
          oldVisit.status &&
          oldVisit.level
        ) {
          state.processing = oldVisit;
        }
      } catch (e) {
        console.log(e);
      }
    }
  },
  registerVisit(state, { newVisit, input, require_photo }) {
    newVisit.barcode_text = input.barcode_text;
    state.processing = newVisit;
    state.require_photo = require_photo;
  },
  setVisitStatus(state, { status, reasonText }) {
    state.processing.status = status;
    state.processing.reason_text = reasonText;
  },
  setVisitAttraction(state, attraction) {
    state.processing.attraction = attraction;
  },
  setVisits(state, visits) {
    state.visits = visits;
  },
  setAcceptedVisits(state, visits) {
    state.accepted_visits = visits;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
