<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="30"
    viewBox="0 0 25 30"
    fill="none"
  >
    <path
      d="M3.33366 29.6666C2.60033 29.6666 1.97255 29.4055 1.45033 28.8833C0.928103 28.361 0.666992 27.7333 0.666992 26.9999V2.99992C0.666992 2.26659 0.928103 1.63881 1.45033 1.11659C1.97255 0.594363 2.60033 0.333252 3.33366 0.333252H12.667V2.99992H3.33366V4.33325H12.667V6.99992H3.33366V22.9999H16.667V20.3333H19.3337V26.9999C19.3337 27.7333 19.0725 28.361 18.5503 28.8833C18.0281 29.4055 17.4003 29.6666 16.667 29.6666H3.33366ZM3.33366 25.6666V26.9999H16.667V25.6666H3.33366ZM18.0003 17.6666L11.3337 10.9999L13.2003 9.13325L16.667 12.5999V2.99992H19.3337V12.5999L22.8003 9.13325L24.667 10.9999L18.0003 17.6666Z"
      fill="black"
    />
  </svg>
</template>
