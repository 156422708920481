/**
 * Created by Alexander Golovnya on 2019-01-07.
 */
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const INIT_AUTH = "INIT_AUTH";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const SET_IF_UPDATE_FOUND = "SET_IF_UPDATE_FOUND";
export const SET_IF_LOGOUT_FOUND = "SET_IF_LOGOUT_FOUND";
export const SET_COORDS = "SET_LATLNG";

export const AUTH_MUTATION_SET_SUPPORT_PHONE =
  "AUTH_MUTATION_SET_SUPPORT_PHONE";
