/**
 * Created by Alexander Golovnya on 2019-01-02.
 */
import axios from "axios";
export default {
  set_header(name, value) {
    axios.defaults.headers.common[name] = value;
  },
  signin(postData) {
    return axios.post("/api/login", postData);
  },
  refreshLogin(refreshToken) {
    return axios.post("/api/login/refresh", undefined, {
      headers: {
        refreshtoken: refreshToken,
      },
    });
  },
  logout() {
    return axios.post("/api/logout");
  },
};
