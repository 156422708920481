<template>
  <a
    href="#"
    tooltip-flow="up"
    class="clipboard-copy-button"
    :tooltip="$t(tooltipMessage)"
    @click.prevent="onClick"
    @mouseleave="onMouseLeave"
  >
    <slot />
  </a>
</template>

<script>
import { computed, ref } from "vue";
import { clipboardCopy } from "@/helpers.js";

const STATES = {
  afterCopy: Symbol("afterCopy"),
  beforeCopy: Symbol("beforeCopy"),
};

const MESSAGES = {
  [STATES.afterCopy]: "after_copy",
  [STATES.beforeCopy]: "before_copy",
};

export default {
  props: {
    value: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const state = ref(STATES.beforeCopy);

    const tooltipMessage = computed(() => MESSAGES[state.value]);

    function onClick() {
      state.value = STATES.afterCopy;
      clipboardCopy(props.value);
    }

    function onMouseLeave() {
      state.value = STATES.beforeCopy;
    }

    return {
      props,
      tooltipMessage,

      onClick,
      onMouseLeave,
    };
  },
};
</script>

<style lang="scss">
.clipboard-copy-button {
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  color: #2954bb;
  text-decoration: none;
}
// https://codepen.io/tutsplus/pen/WROvdG

/* START TOOLTIP STYLES */
[tooltip] {
  position: relative; /* opinion 1 */
}

/* Applies to all tooltips */
[tooltip]::before,
[tooltip]::after {
  text-transform: none; /* opinion 2 */
  font-size: 0.9em; /* opinion 3 */
  line-height: 1;
  user-select: none;
  pointer-events: none;
  position: absolute;
  display: none;
  opacity: 0;
}
[tooltip]::before {
  content: "";
  border: 5px solid transparent; /* opinion 4 */
  z-index: 1001; /* absurdity 1 */
}
[tooltip]::after {
  content: attr(tooltip); /* magic! */

  /* most of the rest of this is opinion */
  font-family: Helvetica, sans-serif;
  text-align: center;

  /*
    Let the content set the size of the tooltips
    but this will also keep them from being obnoxious
    */
  min-width: 3em;
  max-width: 21em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 1ch 1.5ch;
  border-radius: 0.3ch;
  box-shadow: 0 1em 2em -0.5em rgba(0, 0, 0, 0.35);
  background: #333;
  color: #fff;
  z-index: 1000; /* absurdity 2 */
}

/* Make the tooltips respond to hover */
[tooltip]:hover::before,
[tooltip]:hover::after {
  display: block;
}

/* don't show empty tooltips */
[tooltip=""]::before,
[tooltip=""]::after {
  display: none !important;
}

/* FLOW: UP */
[tooltip]:not([tooltip-flow])::before,
[tooltip][tooltip-flow^="up"]::before {
  bottom: 100%;
  border-bottom-width: 0;
  border-top-color: #333;
}
[tooltip]:not([tooltip-flow])::after,
[tooltip][tooltip-flow^="up"]::after {
  bottom: calc(100% + 5px);
}
[tooltip]:not([tooltip-flow])::before,
[tooltip]:not([tooltip-flow])::after,
[tooltip][tooltip-flow^="up"]::before,
[tooltip][tooltip-flow^="up"]::after {
  left: 50%;
  transform: translate(-50%, -0.5em);
}

/* KEYFRAMES */
@keyframes tooltips-vert {
  to {
    opacity: 0.9;
    transform: translate(-50%, 0);
  }
}

/* FX All The Things */
[tooltip]:not([tooltip-flow]):hover::before,
[tooltip]:not([tooltip-flow]):hover::after,
[tooltip][tooltip-flow^="up"]:hover::before,
[tooltip][tooltip-flow^="up"]:hover::after {
  animation: tooltips-vert 300ms ease-out forwards;
}
</style>
