import { createStore, createLogger } from "vuex";
import auth from "./modules/auth";
import error from "./modules/error";
import visits from "./modules/visits";
import documents from "./modules/documents";
import browser from "./modules/browser";
const debug = process.env.NODE_ENV !== "production";

export default createStore({
  modules: { auth, visits, error, documents, browser },
  strict: debug,
  plugins: [createLogger()],
});
