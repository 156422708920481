import store from "@/store";
import { SET_IF_UPDATE_FOUND } from "@/store/mutation-types";

/**
 * Created by Alexander Golovnya on 2019-05-04.
 */
export function AxiosInterceptorOnFullFilled(response) {
  store.commit("auth/" + SET_IF_UPDATE_FOUND, response);
  return response;
}

export function AxiosInterceptorOnRejected(error) {
  if (error && error.message === "Network Error") {
    error = {
      message: "Проверьте интернет соединение",
    };
  }
  store.commit("auth/" + SET_IF_UPDATE_FOUND, error.response);
  let res = error.response;
  if (
    store.getters["auth/getRefreshToken"] &&
    res &&
    res.status === 401 &&
    res.config &&
    !res.config.__isRetryRequest
  ) {
    return store.dispatch("auth/refreshLogin", error);
  }
  store.commit("error/set_error", error);
  throw error;
}
