import { createRouter, createWebHistory } from "vue-router";
import Login from "./views/AppLogin.vue";
import Documents from "./views/Documents";
import AcceptingActiveVisit from "./views/AcceptingActiveVisit";
import RejectActiveVisit from "./views/FinalStatusActiveVisit";
import HolderChooseAttraction from "./views/HolderChooseAttraction";
import ContactInfo from "./views/ContactInfo";
import ViewSupplier from "./views/ViewSupplier";
import VisitIndex from "./views/VisitIndex";
import AcceptedVisitIndex from "./views/AcceptedVisitIndex";
import store from "./store";
import NewRegistration from "./views/NewRegistration.vue";
import AppUpdateInstructions from "./views/AppUpdateInstructions.vue";

let routes = [
  {
    path: "/supplier/login",
    name: "login",
    component: Login,
  },
  {
    path: "/visits/new_registration",
    name: "new_registration",
    component: NewRegistration,
  },
  {
    path: "/settings",
    name: "settings",
    redirect: { name: "contact_info" },
  },
  {
    path: "/documents",
    name: "documents",
    component: Documents,
  },
  {
    path: "/visits/accepting_active_visit",
    name: "accepting_active_visit",
    component: AcceptingActiveVisit,
  },
  {
    path: "/visits/reject_active_visit",
    name: "reject_active_visit",
    component: RejectActiveVisit,
  },
  {
    path: "/visits/status_active_visit",
    name: "status_active_visit",
    component: RejectActiveVisit,
  },
  {
    path: "/visits/attraction",
    name: "holder_choose_attraction",
    component: HolderChooseAttraction,
  },
  {
    path: "/contact_info",
    name: "contact_info",
    component: ContactInfo,
  },
  {
    path: "/supplier",
    name: "supplier",
    component: ViewSupplier,
  },
  {
    path: "/accepted/index",
    name: "index_accepted",
    component: AcceptedVisitIndex,
  },
  {
    path: "/visits/index",
    name: "index_visit",
    component: VisitIndex,
  },
  {
    path: "/app_update",
    name: "app_update",
    component: AppUpdateInstructions,
  },
  {
    path: "/visits/scanning",
    name: "scanning_barcode",
    redirect: { name: "new_registration", query: { t: Date.now().toString() } },
  },
  {
    path: "/visits/accepting",
    name: "accepting_visit",
    redirect: { name: "new_registration", query: { t: Date.now().toString() } },
  },
  {
    path: "/visits/copayment",
    name: "supplier_request_copayment",
    redirect: { name: "new_registration", query: { t: Date.now().toString() } },
  },
  {
    path: "/visits/create",
    name: "create_visit",
    redirect: { name: "new_registration", query: { t: Date.now().toString() } },
  },
  {
    path: "/:catchAll(.*)",
    name: "page_not_found",
    redirect: { name: "new_registration", query: { t: Date.now().toString() } },
  },
];

routes.push({
  path: "/",
  redirect: () => {
    let name = store.getters["auth/getRedirectName"];
    let foundRoutes = routes.filter((value) => {
      return value.name === name;
    });
    return foundRoutes.shift();
  },
});

routes.push({
  path: "/index.html",
  redirect: () => {
    let name = store.getters["auth/getRedirectName"];
    let foundRoutes = routes.filter((value) => {
      return value.name === name;
    });
    return foundRoutes.shift();
  },
});

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
