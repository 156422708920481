/* eslint-disable no-console */

import { register } from "register-service-worker";

function showRefreshUI(registration) {
  // TODO: Display a toast or refresh UI.

  // This demo creates and injects a button.

  var button = document.createElement("button");
  button.style.position = "absolute";
  button.style.bottom = "128px";
  button.style.left = "128px";
  button.textContent =
    "This site has updated. Please click here to see changes.";

  document.addEventListener("click", function () {
    if (!registration.waiting) {
      // Just to ensure registration.waiting is available before
      // calling postMessage()
      return;
    }

    button.disabled = true;

    registration.waiting.postMessage("skipWaiting");
  });

  document.body.appendChild(button);
}

function onNewServiceWorker(registration, callback) {
  if (registration.waiting) {
    // SW is waiting to activate. Can occur if multiple clients open and
    // one of the clients is refreshed.
    return callback();
  }

  function listenInstalledStateChange() {
    registration.installing.addEventListener("statechange", function (event) {
      if (event.target.state === "installed") {
        // A new service worker is available, inform the user
        callback();
      }
    });
  }

  if (registration.installing) {
    return listenInstalledStateChange();
  }

  // We are currently controlled so a new SW may be found...
  // Add a listener in case a new SW is found,
  registration.addEventListener("updatefound", listenInstalledStateChange);
}

if (process.env.NODE_ENV === "production") {
  if ("serviceWorker" in navigator) {
    window.addEventListener("load", function () {
      // When the user asks to refresh the UI, we'll need to reload the window
      navigator.serviceWorker.addEventListener("controllerchange", function () {
        console.log("Controller loaded");
        window.location.reload();
      });
    });
  }

  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        "App is being served from cache by a service worker.\n" +
          "For more details, visit https://goo.gl/AFskqB"
      );
    },
    registered() {
      console.log("Service worker has been registered.");
    },
    cached() {
      console.log("Content has been cached for offline use.");
    },
    updatefound() {
      console.log("New content is downloading.");
    },
    updated(registration) {
      registration.update();

      onNewServiceWorker(registration, () => {
        showRefreshUI(registration);
      });
    },
    offline() {
      console.log(
        "No internet connection found. App is running in offline mode."
      );
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    },
  });
}
