<template>
  <app-layout>
    <div>
      <a ref="download">__</a>
      <h1>{{ $t("doc.title") }}</h1>
      <div>
        {{ $t("avi.search_label") }}
        <input aria-label="filter by" name="query" v-model="filterKey" />
      </div>
      <div>
        <input
          aria-label="choose month"
          type="month"
          name="month_query"
          v-model="monthKey"
        />
        <button @click="monthFilter">{{ $t("avi.month_label") }}</button>
      </div>
      <table>
        <thead>
          <tr>
            <th
              v-for="item in columns"
              @click="sortBy(item.key)"
              :class="{
                active:
                  sortKey === item.key &&
                  typeof sortOrders[item.key] !== 'undefined',
              }"
              :key="item.key"
            >
              {{ capitalize(item.name) }}
              <span
                class="arrow"
                :class="sortOrders[item.key] > 0 ? 'asc' : 'dsc'"
              >
              </span>
            </th>
            <th>{{ $t("doc.actions") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="document in filteredData" :key="document.number">
            <td>{{ document.number }}</td>
            <td>{{ document.supplier_name }}</td>
            <td>{{ document.name }}</td>
            <td>{{ date(document.started_at) }}</td>
            <td>{{ date(document.finished_at) }}</td>
            <td>{{ $d(parseDate(document.created_at), "long") }}</td>
            <td>{{ document.status }}</td>
            <td><button @click="downloadUi(document)">Download</button></td>
          </tr>
        </tbody>
      </table>
    </div>
  </app-layout>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { parseDate, priceIntToFloat } from "@/helpers";
import openBlobOtherWindow from "@/middleware/openBlobOtherWindow";
const today = new Date();
function addZ(n) {
  return (n < 10 ? "0" : "") + n;
}
const date = today.getFullYear() + "-" + addZ(today.getMonth() + 1);
export default {
  name: "ViewDocuments",
  data() {
    return {
      sortKey: 0,
      sortOrders: { created_at: 1, id: 0 },
      filterKey: "",
      monthKey: date,
    };
  },
  computed: {
    columns() {
      return [
        { key: "number", name: this.$i18n.t("doc.number") },
        { key: "supplier_name", name: this.$i18n.t("doc.object") },
        { key: "name", name: this.$i18n.t("doc.name") },
        { key: "started_at", name: this.$i18n.t("doc.started_at") },
        { key: "finished_at", name: this.$i18n.t("doc.finished_at") },
        { key: "created_at", name: this.$i18n.t("doc.created_at") },
        { key: "status", name: this.$i18n.t("doc.status") },
      ];
    },
    ...mapState({
      filteredData(state) {
        let sortKey = this.sortKey;
        let filterKey = this.filterKey && this.filterKey.toLowerCase();
        let order = this.sortOrders[sortKey];
        let data = state.documents.documents;
        if (filterKey) {
          data = data.filter(function (row) {
            return Object.keys(row).some(function (key) {
              return String(row[key]).toLowerCase().indexOf(filterKey) > -1;
            });
          });
        }
        if (sortKey) {
          data = data.slice().sort(function (a, b) {
            a = a[sortKey];
            b = b[sortKey];
            return (a === b ? 0 : a > b ? 1 : -1) * order;
          });
        }
        return data;
      },
    }),
  },
  mounted() {
    this.documents();
  },
  methods: {
    parseDate,
    priceIntToFloat: priceIntToFloat,
    ...mapActions({
      documents: "documents/getGeneratedDocuments",
      download: "documents/downloadById",
    }),
    capitalize(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    date(str) {
      let d = new Date(str);
      if (str && d) {
        return d.toDateString();
      }
    },
    sortBy: function (key) {
      this.sortKey = key;
      this.sortOrders[key] = this.sortOrders[key] * -1;
    },
    monthFilter() {
      this.documents({
        date_range: "month",
        date: this.monthKey,
      });
    },
    downloadUi(document) {
      this.download(document.id).then(
        (file) => {
          openBlobOtherWindow(
            file,
            document.name + ".pdf",
            this.$refs.download
          );
        },
        (error) => {
          if (error.status === 401) {
            this.$router.push({ name: "signin" });
          } else {
            console.log(error.response.data);
          }
        }
      );
    },
  },
};
</script>

<style scoped></style>
