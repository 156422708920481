/**
 * Created by Alexander Golovnya on 2019-01-02.
 */
import axios from "axios";
export default {
  accepted(input) {
    let params = [];
    if (input) {
      if (input.date_range) {
        params.push("date_range=" + input.date_range);
      }
      if (input.date) {
        params.push("date=" + input.date);
      }
    }
    let query = params.length > 0 ? "?" + params.join("&") : "";

    return axios.get(`/api/supplier/0.3.0/accepted_visits${query}`);
  },
  list(input) {
    let params = [];
    if (input) {
      if (input.date_range) {
        params.push("date_range=" + input.date_range);
      }
      if (input.date) {
        params.push("date=" + input.date);
      }
    }
    let query = params.length > 0 ? "?" + params.join("&") : "";

    return axios.get(`/api/supplier/0.3.0/visits${query}`);
  },
  hello(lat = 0.0, lng = 0.0) {
    let query = "";
    if (lat && lng) {
      query = `?lat=${lat}&lng=${lng}`;
    }
    return axios
      .get(`/api/supplier/0.5.0/hello${query}`)
      .then((response) => response.data);
  },
  photo(visit) {
    return axios.get(`/api/supplier/1.0.0/visits/${visit}/user-photo`);
  },
  startRegistration(visit) {
    return axios.post(`/api/supplier/0.3.0/visits`, visit);
  },
  sendVisits(visit) {
    const attractionId = visit.attraction
      ? visit.attraction.id
      : visit.attraction_id;
    return axios.put(`/api/supplier/0.5.0/visits/${visit.id}`, {
      attraction_id: attractionId,
      status: visit.status,
      reason_text: visit.reason_text,
    });
  },
};
