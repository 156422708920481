import { UAParser } from "ua-parser-js";

export const DEVICE_TYPES = {
  mobile: "mobile",
  desktop: "desktop",
};

const BROWSERS = {
  chrome: {
    name: "Chrome",
    icon: "chrome",
    pwa: [DEVICE_TYPES.desktop, DEVICE_TYPES.mobile],
  },
  "mobile chrome": {
    name: "Chrome",
    icon: "chrome",
    pwa: [DEVICE_TYPES.mobile],
  },
  edge: {
    name: "Edge",
    icon: "edge",
    pwa: [DEVICE_TYPES.desktop, DEVICE_TYPES.mobile],
  },
  "mobile firefox": {
    name: "Firefox",
    icon: "firefox",
    pwa: [DEVICE_TYPES.mobile],
  },
  safari: {
    name: "Safari",
    icon: "safari",
    pwa: [DEVICE_TYPES.mobile],
  },
  "mobile safari": {
    name: "Safari",
    icon: "safari",
    pwa: [DEVICE_TYPES.mobile],
  },
};

// initial state
const state = {
  parsedUserAgent: new UAParser()?.getResult() || null,
  browsers: BROWSERS,
};

// getters
const getters = {
  userAgentData(state) {
    return state.parsedUserAgent;
  },
  deviceType(state, getters) {
    const browserName = getters.browserName;
    const deviceType = state.parsedUserAgent?.device?.type;

    const defaultType =
      browserName === "mobile safari" || browserName === "safari"
        ? DEVICE_TYPES.mobile
        : DEVICE_TYPES.desktop;

    return deviceType in DEVICE_TYPES ? deviceType : defaultType;
  },
  desktop(state, getters) {
    return getters.deviceType === DEVICE_TYPES.desktop;
  },
  browserName(state) {
    const detectedName = state.parsedUserAgent?.browser?.name?.toLowerCase();

    return detectedName in state.browsers
      ? detectedName
      : state.browsers["mobile chrome"].name.toLowerCase();
  },
  supportsPWA(state, getters) {
    const name = getters.browserName;

    const isAllowedBrowser =
      name in state.browsers &&
      state.browsers[name].pwa.includes(getters.deviceType);
    return isAllowedBrowser;
  },
};

export default {
  namespaced: true,
  state,
  getters,
};
