<template>
  <small-layout>
    <div>
      <validation-errors></validation-errors>
      <ol>
        <li>
          {{ $t("app.step1") }}<img src="@/assets/img/scan.png" alt="scan" />
        </li>
        <li>{{ $t("app.step2") }}</li>
        <li>{{ $t("app.step3") }}</li>
      </ol>
      <button @click="startRegistration">
        {{ $t("app.scanning") }}<img src="@/assets/img/scan.png" alt="scan" />
      </button>
    </div>
  </small-layout>
</template>
<style lang="scss" scoped>
img {
  vertical-align: middle;
}
ol {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  flex-direction: row;
  align-items: center;
  li {
    box-sizing: border-box;
    flex-grow: 1;
    width: 30%; // Default to full width
    overflow: hidden; // Or flex might bre
    border: solid $background-color 2px;
    border-radius: 5em;
    margin: 1%;
    padding: 1em;
  }
}
button {
  margin: 1em;
  padding: 1em;
  background-color: $background-color;
  color: $primary-color;
  font-size: 2em;
  > img {
    background-color: $background-color;
  }
}
@media screen and (max-width: 767px) {
  ol {
    display: none;
  }
}
</style>

<script>
import { mapActions } from "vuex";
import ValidationErrors from "@/views/ValidationErrors";
export default {
  name: "NewRegistration",
  components: { ValidationErrors },
  data() {
    return {
      qrPaused: false,
      new_route: "",
    };
  },
  mounted() {
    this.checkVisit();
  },
  beforeRouteUpdate(to, from, next) {
    if (to && to.query && !to.query["t"]) {
      next();
      return;
    }
    this.checkVisit();

    next();
  },
  methods: {
    checkVisit() {
      this.setMessage(this.$t("app.looking_visit"));
      const that = this;
      this.getVisit().then((routeName) => {
        that.setMessage("");
        if (typeof routeName === "string" && that.$route.name !== routeName) {
          that.$router.push({ name: routeName });
        }
      });
    },
    ...mapActions({
      setMessage: "error/set_message",
      getVisit: "visits/getVisit",
    }),
    startRegistration() {
      this.$router.push({
        name: "new_registration",
        query: { t: Date.now().toString() },
      });
    },
  },
};
</script>
