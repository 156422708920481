<template>
  <app-layout>
    <div>
      <a
        :href="
          'mailto:' +
          support_email +
          '?Subject=' +
          $t('vs.subject_to_change_info')
        "
        target="_blank"
        >{{ $t("vs.request_to_change_info") }}</a
      >
      <fieldset v-if="is_finance">
        <legend>{{ $t("vs.supplier_agreement_details_label") }}</legend>
        <table>
          <tbody>
            <tr>
              <th>{{ $t("vs.unp_label") }}</th>
              <td>{{ supplier.unp }}</td>
            </tr>
            <tr>
              <th>{{ $t("vs.bank_label") }}</th>
              <td>
                <pre>{{ supplier.bank_details }}</pre>
              </td>
            </tr>
            <tr>
              <th>{{ $t("vs.address_label") }}</th>
              <td>{{ supplier.address }}</td>
            </tr>
            <tr>
              <th>{{ $t("vs.registered_label") }}</th>
              <td>{{ $d(parseDate(supplier.created_at), "long") }}</td>
            </tr>
            <tr>
              <th>{{ $t("vs.updated_label") }}</th>
              <td>{{ $d(parseDate(supplier.updated_at), "long") }}</td>
            </tr>
            <tr>
              <th>{{ $t("vs.published_label") }}</th>
              <td>{{ supplier.published ? $t("vs.yes") : $t("vs.no") }}</td>
            </tr>
          </tbody>
        </table>
      </fieldset>
      <fieldset>
        <legend>{{ $t("vs.supplier_for_customer_label") }}</legend>
        <table>
          <tbody>
            <tr>
              <th>{{ $t("vs.name_label") }}</th>
              <td>{{ supplier.name }}</td>
            </tr>
            <tr>
              <th>{{ $t("vs.info_label") }}</th>
              <td v-html="supplier.general_information"></td>
            </tr>
            <tr>
              <th>{{ $t("vs.extra_label") }}</th>
              <td v-html="supplier.additional"></td>
            </tr>
            <tr>
              <th>{{ $t("vs.address_label") }}</th>
              <td>{{ supplier.address }}</td>
            </tr>
            <tr v-if="is_show_yandex">
              <th>{{ $t("vs.location_label") }}</th>
              <td>
                <a target="_blank" :href="location">{{
                  $t("vs.location_text")
                }}</a>
              </td>
            </tr>
            <tr>
              <th>{{ $t("vs.phone_label") }}</th>
              <td>{{ supplier.contact_phone }}</td>
            </tr>
            <tr>
              <th>{{ $t("vs.website_label") }}</th>
              <td>
                <a target="_blank" :href="supplier.website">{{
                  supplier.website
                }}</a>
              </td>
            </tr>
            <tr>
              <th>{{ $t("vs.hours_label") }}</th>
              <td
                v-html="convert_json_hours_to_list(supplier.opening_hours_text)"
              ></td>
            </tr>
            <tr
              v-if="
                supplier && supplier.attractions && supplier.attractions.length
              "
            >
              <td colspan="2">{{ $t("vs.attractions_label") }}</td>
            </tr>
            <tr>
              <td colspan="2">
                <ul>
                  <li
                    v-for="(attraction, index) in supplier.attractions"
                    :key="index"
                  >
                    {{ attraction.name }}
                    <span v-if="attraction.copayment > 0"
                      >, {{ $t("vs.copayment_required")
                      }}{{
                        $n(priceIntToFloat(attraction.copayment), "currency")
                      }}</span
                    >
                    <span v-if="attraction.passed_cards > 1"
                      >, {{ $t("vs.two_scan_required")
                      }}{{ attraction.passed_cards }}</span
                    >
                    <span v-if="attraction.levels"
                      >, {{ $t("vs.allowed_level_cards")
                      }}{{ getLevelDescription(attraction.levels) }}</span
                    >
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </fieldset>
    </div>
  </app-layout>
</template>
<style lang="scss">
table {
  border-collapse: collapse;
  th,
  td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: left;
  }
  tr:nth-child(even) {
    background-color: #eee;
  }
  tr:nth-child(odd) {
    background-color: #fff;
  }
}
</style>
<script>
import { mapGetters } from "vuex";
import { parseDate, priceIntToFloat } from "@/helpers";
export default {
  name: "ViewSupplier",
  computed: {
    ...mapGetters({
      support_email: "auth/support_email",
      supplier: "auth/getSupplier",
      locale: "auth/locale",
      is_finance: "auth/getIsFinancePosition",
    }),
    is_show_yandex() {
      return String(this.locale).indexOf("UA") === -1;
    },
    location() {
      let lat = this.supplier.lat;
      let lng = this.supplier.lng;
      return (
        "https://static-maps.yandex.ru/1.x/?lang=en_US&l=map&ll=" +
        lng +
        "," +
        lat +
        "&z=17&size=450,450&pt=" +
        lng +
        "," +
        lat +
        ",pm2gnl1"
      );
    },
  },
  methods: {
    convert_json_hours_to_list(value) {
      let openingHours = null;
      let openingHour = null;
      let item = null;
      let list = [];
      let result = [];
      try {
        openingHours = JSON.parse(value);
      } catch (e) {
        console.warn(e);
      }
      if (!openingHours) {
        openingHours = {
          mon: null,
          tue: null,
          wed: null,
          thu: null,
          fri: null,
          sat: null,
          sun: null,
        };
      }
      for (let key in openingHours) {
        openingHour = openingHours[key];
        if (!openingHour) {
          continue;
        }
        if (["last_changed_at", "url"].indexOf(key) !== -1) {
          result.push(key + ": " + openingHour);
          continue;
        }
        list = [];

        for (let subKey in openingHour) {
          item = openingHour[subKey];
          list.push(item[0] + "-" + item[1]);
        }
        result.push(key + ": " + list.join(","));
      }
      return result.join("<br/>");
    },
    parseDate,
    priceIntToFloat: priceIntToFloat,
    getLevelDescription(levels) {
      let descr = "";
      let result = [];
      for (let i = 0; i < levels.length; i++) {
        descr = this.$t("vs.level_card_" + levels[i]);
        if (descr) {
          result.push(descr);
        }
      }
      return result.join("/");
    },
  },
};
</script>
