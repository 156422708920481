/**
 * Created by Alexander Golovnya on 2019-01-02.
 */
function flatErrorResponse(error) {
  if (!error.response) {
    return error;
  }
  return error.response.data;
}

// initial state
const state = {
  errorResponse: {},
  message: "",
  status: "error",
};

// getters
const getters = {
  getErrorResponse(state) {
    return state.errorResponse;
  },
  getMessage(state) {
    return state.message;
  },
  getStatus(state) {
    return state.status;
  },
};

// actions
const actions = {
  async set_error({ commit }, error) {
    commit("set_error", error);
  },
  async set_message({ commit }, message) {
    commit("set_message", message);
  },
};

// mutations
const mutations = {
  set_error(state, error) {
    state.errorResponse = flatErrorResponse(error);
    state.status = "error";
  },
  clear(state) {
    state.errorResponse = {};
  },
  set_message(state, message) {
    state.message = message;
    state.status = "error";
  },
  set_success(state, message) {
    state.message = message;
    state.status = "success";
  },
  clear_message(state) {
    state.message = "";
    state.status = "error";
  },
  set_message_object(state, messageObj) {
    state.message = messageObj.message;
    state.status = messageObj.status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
