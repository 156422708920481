/**
 * Created by Alexander Golovnya on 2019-03-24.
 */
export function priceIntToFloat(value) {
  return isNaN(value) ? NaN : value / 100;
}
export function parseDate(e) {
  if (e) {
    let str = e;
    if (str.date) {
      str = str.date;
    }
    if (str[10] === " ") {
      str = str.replace(" ", "T");
    }
    try {
      return new Date(str);
    } catch (e) {
      console.warn(str + e);
    }
  }
  return undefined;
}

export function parseWeek(e) {
  const date = parseDate(e);
  if (!date) {
    return undefined;
  }
  const onejan = new Date(date.getFullYear(), 0, 1);
  const millisecsInDay = 86400000;
  return Math.ceil(
    ((date - onejan) / millisecsInDay + onejan.getDay() + 1) / 7
  );
}

export function clipboardCopy(text) {
  if (navigator?.clipboard?.writeText) {
    navigator.clipboard.writeText(text);
  }
}
