/**
 * Created by Alexander Golovnya on 2019-01-02.
 */
import api from "../api/document";

// initial state
const state = {
  documents: [],
};

// getters
const getters = {
  getDocuments(state) {
    return state.documents;
  },
};

// actions
const actions = {
  async getGeneratedDocuments({ commit }, input) {
    const response = await api.getDocuments(input);
    const documents = response.data.data;
    commit("SET_DOCUMENTS", documents);
    return documents;
  },
  async downloadById(context, id) {
    const response = await api.getContentById(id);
    const blob = response.data;
    return new Blob([blob], { type: "application/pdf" });
  },
};

// mutations
const mutations = {
  SET_DOCUMENTS(state, documents) {
    state.documents = documents;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
