<template>
  <small-layout>
    <div>
      <div v-if="processing">
        <h3>
          Тип данной карты - {{ $t("vs.level_card_" + processing.level) }}
          <br />
          {{ attraction_count }} услуг(а) по карте на вашем объекте.
        </h3>
        <button
          :disabled="uiDisabled"
          v-if="attraction_count === 0"
          class="button button-warning visit_reject"
          @click="rejectVisit"
        >
          {{ $t("av.reject") }}
        </button>
        <button
          :disabled="attraction.passed_cards === 0 || uiDisabled"
          :class="'attraction_' + key"
          v-for="(attraction, key) in attractions"
          @click="chooseAttraction(attraction)"
          :key="attraction.id"
        >
          {{
            $t("attraction.name", {
              name: attraction.name,
              passed_cards: attraction.passed_cards,
            })
          }}
        </button>
        <validation-errors></validation-errors>
        <button
          :disabled="uiDisabled"
          class="button button-warning visit_reject"
          @click="rejectVisit"
        >
          {{ $t("av.reject") }}
        </button>
      </div>
      <div v-else>
        <router-link :to="`/visits/new_registration?t=${Date.now()}`">{{
          $t("app.new_registration")
        }}</router-link>
      </div>
    </div>
  </small-layout>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ValidationErrors from "@/views/ValidationErrors";
export default {
  name: "holderChooseAttraction",
  components: { ValidationErrors },
  computed: {
    ...mapGetters({
      attractions: "auth/getAttractionsByScannedCards",
      processing: "visits/getActiveVisit",
    }),
    attraction_count() {
      return this.attractions.filter(
        (attraction) => attraction.passed_cards !== 0
      ).length;
    },
  },
  data() {
    return {
      new_route: "",
      uiDisabled: false,
    };
  },
  methods: {
    ...mapActions({
      attachAttraction: "visits/attachAttractionToVisit",
      rejected: "visits/rejected",
    }),
    async chooseAttraction(attraction) {
      if (attraction.passed_cards === 0) {
        return;
      }
      this.uiDisabled = true;
      this.new_route = await this.attachAttraction(attraction);
      this.uiDisabled = false;
      if (this.$route.name !== this.new_route) {
        this.$router.push({ name: this.new_route });
      }
    },
    async rejectVisit() {
      this.uiDisabled = true;
      await this.rejected();
      this.uiDisabled = false;
      this.$router.push({
        name: "new_registration",
        query: { t: Date.now().toString() },
      });
    },
  },
};
</script>

<style scoped lang="scss">
button {
  width: 100%;
  height: 100px;
  display: block;
}
button:disabled {
  color: gray;
  background-color: lightgray;
  text-decoration: line-through;
}
.button {
  width: 100%;
  &.button-warning {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
  }
  &.button-success {
    color: #fff;
    background-color: #5cb85c;
    border-color: #4cae4c;
  }
}
</style>
