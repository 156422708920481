<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="7"
    height="12"
    viewBox="0 0 7 12"
    fill="none"
  >
    <path
      d="M4.3498 6.27275L0.449805 2.37275C0.266471 2.18942 0.174805 1.95609 0.174805 1.67275C0.174805 1.38942 0.266471 1.15609 0.449805 0.972754C0.633138 0.789421 0.866471 0.697754 1.1498 0.697754C1.43314 0.697754 1.66647 0.789421 1.8498 0.972754L6.4498 5.57275C6.5498 5.67275 6.62064 5.78109 6.6623 5.89775C6.70397 6.01442 6.7248 6.13942 6.7248 6.27275C6.7248 6.40609 6.70397 6.53109 6.6623 6.64775C6.62064 6.76442 6.5498 6.87275 6.4498 6.97275L1.8498 11.5728C1.66647 11.7561 1.43314 11.8478 1.1498 11.8478C0.866471 11.8478 0.633138 11.7561 0.449805 11.5728C0.266471 11.3894 0.174805 11.1561 0.174805 10.8728C0.174805 10.5894 0.266471 10.3561 0.449805 10.1728L4.3498 6.27275Z"
      fill="white"
    />
  </svg>
</template>
