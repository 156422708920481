<template>
  <small-layout>
    <div>
      <img
        v-if="status === 'error'"
        src="@/assets/img/reject.png"
        alt="reject"
      />
      <div v-if="processing && processing.holder" class="flex-container">
        <h2 class="flex-cell accepting-visit-one-line">
          {{ processing.holder }}
        </h2>
      </div>
      <div class="flex-container">
        <h4
          class="flex-cell accepting-visit-break-word"
          v-if="processing.level"
        >
          {{ $t("vs.level_title") }}
          {{ $t("vs.level_card_" + processing.level) }}
        </h4>
        <h4
          class="flex-cell accepting-visit-break-word"
          v-if="processing.attraction && processing.attraction.id"
        >
          {{ $t("vs.attraction_title") }} {{ processing.attraction.name }}
        </h4>
        <h4 class="flex-cell accepting-visit-break-word">
          {{ $t(`${processing.aggregator}`) }}
        </h4>
      </div>
      <validation-errors></validation-errors>
      <button @click="startRegistration">
        {{ $t("app.new_registration") }}
      </button>
      <div v-if="!processing">
        <a href="#" @click="doLogout">{{ $t("app.quit") }}</a>
      </div>
    </div>
  </small-layout>
</template>

<style lang="scss">
button {
  background-color: $background-color;
  color: $primary-color;
  font-size: 2em;
}
.glyphicon {
}
.glyphicon-info-sign {
  width: 16px;
  height: 16px;
  padding: 0 16px;
  background-image: url("../../public/img/icons/info-circle-outline-32.png");
}
</style>
<style lang="scss" scoped>
img {
  float: left;
}
@media screen and (max-width: 767px) {
  img {
    display: none;
  }
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";
import { isActivated } from "@/middleware/filters";
import ValidationErrors from "@/views/ValidationErrors";
export default {
  name: "FinalStatusActiveVisit",
  components: { ValidationErrors },
  computed: {
    ...mapGetters({
      processing: "visits/getActiveVisit",
      status: "error/getStatus",
    }),
  },
  methods: {
    startRegistration() {
      this.$router.push({ name: "new_registration" });
    },
    ...mapActions({
      logout: "auth/logout",
    }),
    async doLogout() {
      try {
        await this.logout();
        this.$router.push({ name: "login" });
      } catch (response) {
        this.$router.push({ name: "login" });
      }
    },
  },
  filters: { isActivated },
};
</script>
