/**
 * Created by Alexander Golovnya on 2019-01-02.
 */
import axios from "axios";
export default {
  getDocuments(input) {
    let params = [];
    if (input) {
      if (input.date_range) {
        params.push("date_range=" + input.date_range);
      }
      if (input.date) {
        params.push("date=" + input.date);
      }
    }
    let query = params.length > 0 ? "?" + params.join("&") : "";

    return axios.get(`/api/supplier/0.3.0/documents${query}`);
  },
  getContentById(id) {
    return axios.get(`/api/supplier/0.3.0/documents/${id}/download`, {
      responseType: "blob", // Force to receive data in a Blob Format
    });
  },
};
