<style lang="scss" scoped>
$break-small: 960px;
.responsive-fieldset {
  .responsive-block {
    @media (max-width: $break-small) {
      width: 100%;
      box-sizing: border-box;
    }
  }
}
.action-link {
  cursor: pointer;
}
button {
  background-color: $background-color;
  color: $primary-color;
  font-size: 2em;
}
.right {
  float: right;
}
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
nav {
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #333;
  > a {
    display: inline-block;
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    :hover:not(.active) {
      background-color: #111;
    }

    .active {
      background-color: #4caf50;
    }
  }
}
img {
  float: right;
}
.flex-container {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  flex-direction: row-reverse;
}
form {
  box-sizing: border-box;
  flex-grow: 1;
  width: 50%; // Default to full width
  overflow: hidden; // Or flex might bre
}
img {
  box-sizing: border-box;
  flex-grow: 1;
  width: 50%; // Default to full width
  overflow: hidden; // Or flex might bre
}
.right {
  float: right;
}
@media screen and (max-width: 767px) {
  img {
    width: 100%;
    clear: both;
  }
}
</style>

<template>
  <div id="app">
    <nav class="attached-nav">
      <router-link class="action-link" to="/supplier/login">{{
        $t("app.login")
      }}</router-link>
      <router-link to="/contact_info">{{ $t("app.info") }}</router-link>
      <a class="right" :href="support.tel" target="_blank">{{
        $t("app.support_title", { tel: support.tel_title })
      }}</a>
    </nav>
    <validation-errors
      :response="errorResponse"
      v-if="errorResponse"
    ></validation-errors>
    <transition name="fade">
      <div class="flex-container">
        <form @submit.prevent="signinUser">
          <fieldset class="responsive-fieldset">
            <legend>{{ $t("l.title") }}</legend>
            <div>
              <label for="email">{{ $t("l.email") }}</label>
              <input
                class="responsive-block"
                autocomplete="email"
                id="email"
                type="email"
                name="email"
                v-model="email"
                required
                autofocus
              />
            </div>
            <div>
              <label for="password">{{ $t("l.password") }}</label>
              <input
                class="responsive-block"
                autocomplete="current-password"
                id="password"
                type="password"
                name="password"
                v-model="password"
                required
              />
            </div>
            <div>
              <label for="set_locale">{{ $t("app.language") }}</label>
              <select id="set_locale" name="locale" v-model="form_locale">
                <option
                  v-for="(language, key) in languages"
                  :value="key"
                  :key="key"
                >
                  {{ language }}
                </option>
              </select>
            </div>
            <div>
              <button>{{ $t("l.confirm") }}</button>
            </div>
          </fieldset>
          <hr />
          <div>
            {{ $t("app.forgot_password") }}
            <a :href="support.tel" target="_blank">{{ support.tel_title }}</a>
          </div>
        </form>
        <img
          v-if="String(locale).indexOf('BY') !== -1"
          src="@/assets/img/signin.jpg"
          alt="Sign-in"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ValidationErrors from "../views/ValidationErrors";
export default {
  components: {
    ValidationErrors,
  },
  data() {
    return {
      email: undefined,
      password: undefined,
      locale: undefined,
    };
  },
  methods: {
    ...mapActions({
      signin: "auth/signin",
      setLanguage: "auth/setLanguage",
    }),
    async signinUser() {
      this.setLanguage(this.form_locale);
      const routeName = await this.signin({
        email: this.email,
        password: this.password,
      });
      if (routeName !== "" && routeName !== this.$route.name) {
        this.$router.push({ name: routeName, query: { t: Date.now() } });
      }
    },
  },
  computed: {
    ...mapGetters({
      support: "auth/support",
      current_locale: "auth/locale",
      errorResponse: "error/getErrorResponse",
      languages: "auth/locales",
    }),
    form_locale: {
      get() {
        if (this.locale) {
          return this.locale;
        }
        return this.current_locale;
      },
      set(value) {
        this.locale = value;
        this.setLanguage(value);
      },
    },
  },
};
</script>
