<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
  >
    <path
      d="M10.6667 19.3942H13.3333V14.0609H18.6667V11.3942H13.3333V6.06087H10.6667V11.3942H5.33333V14.0609H10.6667V19.3942ZM2.66667 24.7275C1.93333 24.7275 1.30556 24.4664 0.783333 23.9442C0.261111 23.422 0 22.7942 0 22.0609V3.39421C0 2.66087 0.261111 2.03309 0.783333 1.51087C1.30556 0.98865 1.93333 0.727539 2.66667 0.727539H21.3333C22.0667 0.727539 22.6944 0.98865 23.2167 1.51087C23.7389 2.03309 24 2.66087 24 3.39421V22.0609C24 22.7942 23.7389 23.422 23.2167 23.9442C22.6944 24.4664 22.0667 24.7275 21.3333 24.7275H2.66667ZM2.66667 22.0609H21.3333V3.39421H2.66667V22.0609Z"
      fill="black"
    />
  </svg>
</template>
