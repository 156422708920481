<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="24"
    viewBox="0 0 28 24"
    fill="none"
  >
    <path
      d="M8.6665 24V21.3333H3.33317C2.59984 21.3333 1.97206 21.0722 1.44984 20.55C0.927615 20.0278 0.666504 19.4 0.666504 18.6667V2.66667C0.666504 1.93333 0.927615 1.30556 1.44984 0.783333C1.97206 0.261111 2.59984 0 3.33317 0H13.9998V2.66667H3.33317V18.6667H24.6665V14.6667H27.3332V18.6667C27.3332 19.4 27.0721 20.0278 26.5498 20.55C26.0276 21.0722 25.3998 21.3333 24.6665 21.3333H19.3332V24H8.6665ZM20.6665 14.6667L13.9998 8L15.8665 6.13333L19.3332 9.56667V0H21.9998V9.56667L25.4665 6.13333L27.3332 8L20.6665 14.6667Z"
      fill="black"
    />
  </svg>
</template>
