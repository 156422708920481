<template>
  <component :is="currentIcon" v-bind="iconColor" />
</template>

<script>
import ChromeIcon from "@/components/icons/simple/ChromeIcon.vue";
import EdgeIcon from "@/components/icons/simple/EdgeIcon.vue";
import FirefoxIcon from "@/components/icons/simple/FirefoxIcon.vue";
import SafariIcon from "@/components/icons/simple/SafariIcon.vue";

import SafariInstallIcon from "@/components/icons/simple/SafariInstallIcon.vue";
import MobileInstallIcon from "@/components/icons/simple/MobileInstallIcon.vue";
import EdgeInstallIcon from "@/components/icons/simple/EdgeInstallIcon.vue";
import ChromeInstallIcon from "@/components/icons/simple/ChromeInstallIcon.vue";

import MenuIcon from "@/components/icons/simple/MenuIcon.vue";
import SafariMenuIcon from "@/components/icons/simple/SafariMenuIcon.vue";

import ArrowRight from "@/components/icons/simple/ArrowRightIcon.vue";

export const DEFAULT_ICON_COLOR = "#999";

const SIMPLE_ICONS = {
  "safari-install": SafariInstallIcon,
  "mobile-install": MobileInstallIcon,
  "edge-install": EdgeInstallIcon,
  "chrome-install": ChromeInstallIcon,
  "safari-menu": SafariMenuIcon,
  "arrow-right": ArrowRight,
  chrome: ChromeIcon,
  edge: EdgeIcon,
  firefox: FirefoxIcon,
  safari: SafariIcon,
  menu: MenuIcon,
};

export const SIMPLE_ICONS_LIST = Object.keys(SIMPLE_ICONS);

export default {
  name: "BaseIcon",
  props: ["icon", "color"],
  computed: {
    iconColor() {
      let colorProperty = "fill";

      return {
        [colorProperty]: this.$props.color ?? DEFAULT_ICON_COLOR,
      };
    },
    currentIcon() {
      return SIMPLE_ICONS[this.$props.icon];
    },
  },
};
</script>
