<template>
  <app-layout>
    <div>
      <h1>{{ $t("vi.title") }}</h1>
      <div>
        {{ $t("vi.search_label") }}
        <input aria-label="filter by" name="query" v-model="filterKey" />
      </div>
      <div>
        <button @click="todayFilter">{{ $t("avi.today_label") }}</button>
        <button @click="weekFilter">{{ $t("avi.week_label") }}</button>
        <button @click="monthFilter">{{ $t("avi.month_label") }}</button>
        <input
          aria-label="choose month"
          type="month"
          name="month_query"
          v-model="monthKey"
          @change="monthFilter"
        />
      </div>
      <table>
        <thead>
          <tr>
            <th
              v-for="item in columns"
              @click="sortBy(item.key)"
              :class="{
                active:
                  sortKey === item.key &&
                  typeof sortOrders[item.key] !== 'undefined',
              }"
              :key="item.key"
            >
              {{ capitalize(item.name) }}
              <span
                class="arrow"
                :class="sortOrders[item.key] > 0 ? 'asc' : 'dsc'"
              >
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="entry in filteredData" :key="entry.id">
            <td>{{ $d(parseDate(entry.created_at), "long") }}</td>
            <td>{{ entry.id }}</td>
            <td>{{ $t("vv.visit_" + entry.status) }}</td>
            <td>{{ entry.reason_text }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </app-layout>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { parseDate } from "@/helpers";
const today = new Date();
function addZ(n) {
  return (n < 10 ? "0" : "") + n;
}
const date = today.getFullYear() + "-" + addZ(today.getMonth() + 1);
export default {
  data: function () {
    return {
      sortKey: 0,
      sortOrders: { created_at: 1, id: 0, status: 0, reason_text: 0 },
      filterKey: "",
      monthKey: date,
      range: "day",
      columns: [
        { key: "created_at", name: this.$i18n.t("vi.datetime_label") },
        { key: "id", name: this.$i18n.t("vi.id_label") },
        { key: "status", name: this.$i18n.t("vi.status_label") },
        { key: "reason_text", name: this.$i18n.t("vi.reason_label") },
      ],
    };
  },
  computed: {
    ...mapState({
      filteredData(state) {
        let sortKey = this.sortKey;
        let filterKey = this.filterKey && this.filterKey.toLowerCase();
        let order = this.sortOrders[sortKey];
        let data = state.visits.visits;
        if (filterKey) {
          data = data.filter(function (row) {
            return Object.keys(row).some(function (key) {
              return String(row[key]).toLowerCase().indexOf(filterKey) > -1;
            });
          });
        }
        if (sortKey) {
          data = data.slice().sort(function (a, b) {
            a = a[sortKey];
            b = b[sortKey];
            return (a === b ? 0 : a > b ? 1 : -1) * order;
          });
        }
        return data;
      },
    }),
  },
  mounted() {
    this.getVisits();
  },
  methods: {
    parseDate,
    ...mapActions("visits", ["getVisits"]),
    capitalize(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    sortBy: function (key) {
      this.sortKey = key;
      this.sortOrders[key] = this.sortOrders[key] * -1;
    },
    todayFilter() {
      this.monthKey = date;
      this.range = "today";
      this.getVisits({
        date_range: "today",
        date: this.monthKey,
      });
    },
    weekFilter() {
      this.monthKey = date;
      this.range = "week";
      this.getVisits({
        date_range: "week",
        date: this.monthKey,
      });
    },
    monthFilter() {
      this.range = "month";
      this.getVisits({
        date_range: "month",
        date: this.monthKey,
      });
    },
  },
};
</script>

<style scoped></style>
