<template>
  <small-with-back-button-layout>
    <fieldset>
      <button @click="startRegistration">
        {{ $t("app.new_registration") }}
      </button>
      <div>Local: {{ version }}</div>
      <div>API: {{ getLocalStorageVersion }}</div>
      <div>
        <a :href="instructionUri" target="_blank">{{
          $t("ci.instruction_label1")
        }}</a>
        - {{ $t("ci.instruction_label2") }}
      </div>
    </fieldset>
  </small-with-back-button-layout>
</template>
<script>
import packageJson from "../../package";
import { mapGetters } from "vuex";
// const instructionUri = process.env.VUE_APP_INSTRUCTION_URI
export default {
  name: "contact_info",
  data() {
    return {
      version: packageJson.version,
    };
  },
  methods: {
    startRegistration() {
      this.$router.push({
        name: "new_registration",
        query: { t: Date.now().toString() },
      });
    },
  },
  computed: {
    ...mapGetters({
      app_has_new_version: "auth/appHasNewVersion",
      instructionUri: "auth/instruction",
    }),
    getLocalStorageVersion: {
      get() {
        if (this.app_has_new_version) {
          document.location.reload();
          return "hello";
        }
        return localStorage.getItem("x-supplier-version");
      },
    },
  },
};
</script>
